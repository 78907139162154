import React from 'react';
import { graphql } from 'gatsby';
import TitlePage from '../components/TitlePage';
import SEO from '../components/seo';
import Container from '../components/Container'
import RichText from '../components/RichText'
import useTranslations from '../components/useTranslations';
import BackLink from '../components/BackLink';
import Helmet from 'react-helmet';
import useResponsiveImage from '../components/useResponsiveImage';

import * as S from './post.styled';



const Post = props => {
  const locale = props.data.markdownRemark.fields.locale;
  const post = props.data.markdownRemark;
  const folder = props.data.markdownRemark.frontmatter.folder;
  const noIndex = props.data.markdownRemark.frontmatter.noIndex;

  const {
    by,
  } = useTranslations(locale);  

  return (
    <Container>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
      />

      { noIndex &&
        <Helmet>
          <meta name="robots" content="noindex,nofollow"/>
        </Helmet>
      }

      {folder && 
        <BackLink folder={folder} lang={locale}></BackLink>
      }
      
      <S.PostWrapper>

          <S.PostImgWrapper>
            <S.PostImg
              fluid={useResponsiveImage(post.frontmatter.image)}
              alt={post.frontmatter.title}
            />
          </S.PostImgWrapper>


        <S.PostInfo>
          <S.PostDate>{post.frontmatter.date}</S.PostDate>
          <S.PostAuthor>{by} {post.frontmatter.author}</S.PostAuthor>
          <TitlePage text={post.frontmatter.title} />
          <S.PostDescription>{post.frontmatter.description}</S.PostDescription>
          <RichText dangerouslySetInnerHTML={{ __html: post.html }}/>


          {folder && 
            <BackLink folder={folder} lang={locale}></BackLink>
          }

        </S.PostInfo>


      </S.PostWrapper>

    </Container>
  );
};

export const query = graphql`
  query Post($locale: String!, $dateFormat: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }

    ) {
      fields {
        locale
      }      
      frontmatter {
        templateKey
        title
        date(formatString: $dateFormat, locale:$locale)
        author
        description
        image
        folder
        noIndex
      }
      html
    }  
  }
`;

export default Post;
